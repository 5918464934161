import {
  CdkDrag,
  CdkDragHandle,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventFaq } from '@models/events/event-faq.model';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { EventStore } from '@services/stores/event.store';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { UpdateActions } from '@models/shared/enums/update-actions.enum';

@Component({
  selector: 'app-event-section-faq',
  standalone: true,
  imports: [
    CommonModule,
    CdkAccordionModule,
    SafeHtmlPipe,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
  ],
  providers: [NotificationService],
  templateUrl: './event-section-faq.component.html',
  styleUrl: './event-section-faq.component.scss',
})
export class EventSectionFaqComponent {
  private _faqs: EventFaq[] = [];
  @Input()
  set faqs(value: EventFaq[] | undefined) {
    if (value) {
      this._faqs = value.sort((a, b) => a.order - b.order);
    }
  }

  @Input() event?: Event;
  @Input() isEdit?: boolean = false;
  @Input() layout?: EventTemplateLayout;
  @Input() bodyColor?: string;
  @Output() onEdit = new EventEmitter<ConfigurableSectionItem>();
  draggedFaq?: EventFaq;

  get faqs(): EventFaq[] | undefined {
    return this._faqs;
  }

  get isUaSection(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }

  constructor(
    private eventStore: EventStore,
    private dialog: MatDialog,
    private eventService: EventService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {}

  drop(event: CdkDragDrop<any[]>, draggedFaq?: EventFaq) {
    if (this.faqs)
      moveItemInArray(this.faqs, event.previousIndex, event.currentIndex);

    if (draggedFaq) {
      this.eventService
        .updateEvent(this.event?.id, {
          faq: {
            id: this.draggedFaq?.id,
            question: this.draggedFaq?.question,
            answer: this.draggedFaq?.answer,
            order: event.currentIndex,
          },
        })
        .subscribe({
          next: () => {
            if (this.event) this.eventStore.refreshEvent(this.event.uri);
          },
        });
    }
  }

  update(event: any, action: UpdateActions, faq: EventFaq) {
    event.stopPropagation();
    switch (action) {
      case UpdateActions.EDIT: {
        this.onEdit.emit({
          faq: faq,
        });
        break;
      }
      case UpdateActions.DELETE: {
        this.removeFaq(faq);
      }
    }
  }

  removeFaq(faq: EventFaq) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_TITLE',
        message: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_CONFIRMATION',
        yesLabel: 'APP.YES',
        font: this.event?.eventTemplate?.font,
        isDeleteDialog: true,
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        this.eventService
          .updateEvent(this.event?.id, {
            faqDeleteId: faq.id,
          })
          .subscribe({
            next: () => {
              if (this.event?.uri) {
                this.eventStore.refreshEvent(this.event?.uri);
              }
            },
            error: () => {
              this.notificationService.error(
                this.translateService.instant(
                  'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ERROR',
                ),
              );
            },
          });
      }
    });
  }

  protected readonly Event = Event;
  protected readonly EventType = EventType;
  protected readonly UpdateActions = UpdateActions;
  protected readonly EventTemplateLayout = EventTemplateLayout;
}
