import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgForOf } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventWishlistItem } from '@models/events/event-wishlist-item.model';
import { Event } from '@models/events/event.model';
import { getAssetFromURL } from '@models/shared/asset.model';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { EventCardComponent } from '@modules/events/components/event-card/event-card.component';
import { EventSectionSponsorItemComponent } from '@modules/events/components/event-section-sponsor-item/event-section-sponsor-item.component';
import { EventSectionWishlistItemComponent } from '@modules/events/components/event-section-wishlist-item/event-section-wishlist-item.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { EventStore } from '@services/stores/event.store';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../../../directives/swiper.directive';
import { CamelCasePipe } from '../../../../pipes/camel-case.pipe';
import { UpdateActions } from '@models/shared/enums/update-actions.enum';

@Component({
  selector: 'app-event-section-wishlist',
  standalone: true,
  imports: [
    EventCardComponent,
    SwiperDirective,
    CdkDropList,
    CamelCasePipe,
    CdkDrag,
    EventSectionSponsorItemComponent,
    MatMenu,
    EventSectionWishlistItemComponent,
    CdkDragHandle,
    MatMenuTrigger,
    MatMenuItem,
    TranslateModule,
    NgForOf,
  ],
  templateUrl: './event-section-wishlist.component.html',
  styleUrl: './event-section-wishlist.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventSectionWishlistComponent implements OnInit {
  @Input() event?: Event;
  @Input() isEdit?: boolean = false;
  wishlistItems?: EventWishlistItem[];
  draggedWishlistItem?: EventWishlistItem;
  @Output() onEdit = new EventEmitter<ConfigurableSectionItem>();
  @Output() configureSection = new EventEmitter<void>();

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    speed: 2000,
    spaceBetween: 16,
    centeredSlides: false,
    injectStyles: [
      `
        :host .swiper { overflow: visible;
          .swiper-wrapper{
        display: flex; justify-content: space-between; max-width: 264px;
        }}
      `,
    ],
    breakpoints: {
      5120: {
        slidesPerView: 3,
      },
    },
  };

  constructor(
    private eventStore: EventStore,
    private dialog: MatDialog,
    private eventService: EventService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      this.event = event;
      if (this.event && this.event.eventTemplate?.eventWishlistItems) {
        this.wishlistItems = this.event.eventTemplate.eventWishlistItems.sort(
          (a, b) => a.order - b.order,
        );
      }
    });
  }

  ngOnInit(): void {
    if (this.event && this.event.eventTemplate?.eventWishlistItems) {
      this.wishlistItems = this.event.eventTemplate.eventWishlistItems.sort(
        (a, b) => a.order - b.order,
      );
    }
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  goToLink(item: EventWishlistItem) {
    if (!item.linkUrl) {
      return;
    }
    window.open(item.linkUrl, '_blank');
  }

  drop(event: CdkDragDrop<any[]>, draggedWishlistItem?: EventWishlistItem) {
    if (this.wishlistItems) {
      moveItemInArray(
        this.wishlistItems,
        event.previousIndex,
        event.currentIndex,
      );
    }

    if (draggedWishlistItem) {
      this.eventService
        .updateEvent(this.event?.id, {
          wishlistItem: {
            id: this.draggedWishlistItem?.id,
            linkUrl: this.draggedWishlistItem?.linkUrl,
            pictureAsset: this.draggedWishlistItem?.pictureUrl
              ? getAssetFromURL(this.draggedWishlistItem?.pictureUrl)
              : undefined,
            order: event.currentIndex,
          },
        })
        .subscribe({
          next: () => {
            if (this.event) this.eventStore.refreshEvent(this.event?.uri);
          },
        });
    }
  }

  onUpdate(action: UpdateActions, item: EventWishlistItem) {
    if (action === UpdateActions.EDIT) {
      this.onEdit.emit({
        eventWishlistItem: item,
      });
    } else if (action === UpdateActions.COPY) {
      const newItem = {
        isCopy: true,
        id: 0,
        linkUrl: item.linkUrl,
        pictureAsset: item.pictureUrl
          ? getAssetFromURL(item.pictureUrl)
          : undefined,
        order: item.order,
      };
      const eventRequest = {
        wishlistItem: newItem,
      };
      this.update(eventRequest);
    } else if (action === UpdateActions.DELETE) {
      this.removeItem(item);
    }
  }

  removeItem(item: EventWishlistItem) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_TITLE',
        message: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_CONFIRMATION',
        yesLabel: 'APP.YES',
        font: this.event?.eventTemplate?.font,
        isDeleteDialog: true,
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        this.eventService
          .updateEvent(this.event?.id, {
            wishlistItemDeleteId: item.id,
          })
          .subscribe({
            next: () => {
              if (this.event?.uri) {
                this.eventStore.refreshEvent(this.event?.uri);
              }
            },
            error: () => {
              this.notificationService.error(
                this.translateService.instant(
                  'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ERROR',
                ),
              );
            },
          });
      }
    });
  }
  update(req?: EventUpdateRequest) {
    if (this.event) {
      if (req) {
        req.localization = this.translateService.currentLang;
      }

      this.eventService.updateEvent(this.event.id, req).subscribe((event) => {
        this.event = event;
        this.eventStore.setEvent(event);
      });
    }
  }

  onConfigureSection() {
    this.configureSection.emit();
  }

  protected readonly UpdateActions = UpdateActions;
  protected readonly EventTemplateLayout = EventTemplateLayout;
}
