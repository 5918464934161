import { NgStyle, UpperCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventTimetableItem } from '@models/events/event-timetable-item.model';
import { EventTimetable } from '@models/events/event-timetable.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { EventStore } from '@services/stores/event.store';
import { CamelCasePipe } from '../../../../pipes/camel-case.pipe';
import { EventSectionTimetableItemComponent } from '../event-section-timetable-item/event-section-timetable-item.component';
import { UpdateActions } from '@models/shared/enums/update-actions.enum';

@Component({
  selector: 'app-event-section-timetable',
  standalone: true,
  imports: [
    EventSectionTimetableItemComponent,
    TranslateModule,
    UpperCasePipe,
    CamelCasePipe,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    NgStyle,
  ],
  providers: [NotificationService],
  templateUrl: './event-section-timetable.component.html',
  styleUrl: './event-section-timetable.component.scss',
})
export class EventSectionTimetableComponent implements OnInit, OnChanges {
  @Input() eventTimetable?: EventTimetable;
  @Input() eventType: EventType = EventType.INVITATION;
  @Input() isUaEvent = false;
  @Input() isWeddingPage = false;
  @Input() isEdit?: boolean = false;
  @Input() selectedDay?: Date;
  @Input() layout?: EventTemplateLayout;
  @Input() bodyColor?: string;
  @Output() onEdit = new EventEmitter<ConfigurableSectionItem>();
  all: boolean = true;
  filteredItems?: EventTimetableItem[];
  dates: Date[] = [];
  event?: Event;
  selectedItem?: EventTimetableItem;
  /*   @Input()
  set eventTimetable(value: EventTimetable | undefined) {
    this._eventTimetable = value;
    if (value) {
      this._eventTimetable = value;
    }
  } */
  constructor(
    private eventStore: EventStore,
    private dialog: MatDialog,
    private eventService: EventService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      this.event = event;
    });
  }
  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.filteredItems = this.sortByDate(this.eventTimetable?.items);
    this.eventTimetable?.items?.forEach((x) => {
      if (!this.dates.includes(x.day)) {
        this.dates.push(x.day);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventTimetable']?.currentValue) {
      this.eventTimetable = changes['eventTimetable'].currentValue;
      this.initData();
    }
  }
  onUpdate(action: UpdateActions, timeTableItem: EventTimetableItem) {
    if (action === UpdateActions.EDIT) {
      this.onEdit.emit({
        timeTableItem: timeTableItem,
        day: this.selectedDay,
      });
    } else if (action === UpdateActions.DELETE) {
      this.removeTimeTableItem(timeTableItem);
    }
  }

  selectItem(item: EventTimetableItem) {
    if (this.selectedItem !== item) {
      this.selectedItem = item;
      this.onUpdate(UpdateActions.EDIT, item);
    } else {
      this.selectedItem = undefined;
    }
  }
  sortByDate(data?: EventTimetableItem[]): EventTimetableItem[] | undefined {
    return data?.sort((a, b) => {
      const timeA = new Date(`1970-01-01T${a.timeFrom}:00Z`).getTime();
      const timeB = new Date(`1970-01-01T${b.timeFrom}:00Z`).getTime();
      return timeA - timeB;
    });
  }

  removeTimeTableItem(timetableItem: EventTimetableItem) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_TITLE',
        message: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_CONFIRMATION',
        yesLabel: 'APP.YES',
        font: this.event?.eventTemplate?.font,
        isDeleteDialog: true,
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        this.eventService
          .updateEvent(this.event?.id, {
            timetableItemDeleteId: timetableItem.id,
          })
          .subscribe({
            next: () => {
              if (this.event?.uri) {
                this.eventStore.refreshEvent(this.event?.uri);
              }
            },
            error: () => {
              this.notificationService.error(
                this.translateService.instant(
                  'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ERROR',
                ),
              );
            },
          });
      }
    });
  }
  protected readonly EventType = EventType;
  protected readonly UpdateActions = UpdateActions;
  protected readonly EventTemplateLayout = EventTemplateLayout;
}
